
.section__lk-content-items-title {
  background: #002E5E;
  padding: 3rem 4.8rem;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.4rem;
  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
}
